/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(document).ready(function(){
          $('.site-header__menu-toggle').on('click',function(){
            $('body').toggleClass('mobile-menu-displayed');
          });
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $.fn.randomize = function(selector){
          var $elems = selector ? $(this).find(selector) : $(this).children(),
              $parents = $elems.parent();

          $parents.each(function(){
              $(this).children(selector).sort(function(){
                  return Math.round(Math.random()) - 0.5;
              }).detach().appendTo(this);
          });

          return this;
        };

        // randomize the slides and then call slick
        $('.hero-slides').randomize('.hero-slide');
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        // init hero slider
        $('.hero-slides').slick({
          lazyLoad: 'progressive',
          infinite: true,
          fade: true,
          cssEase: 'linear',
          swipe: false,
          pauseOnHover: false,
          autoplay: true,
          autoplaySpeed: 8000,
          speed: 1500,
          arrows: false,
          draggable: false
        });

        // init rooms slider 
        $('.slider__items').slick({
          centerMode: true,
          centerPadding: '8.333%',
          slidesToShow: 1,
          lazyLoad: 'progressive',
          variableWidth: true,
          prevArrow: '<div class="slider__arrow slider__arrow--prev"><svg width="82px" height="56px" viewBox="0 0 82 56" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs></defs><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g transform="translate(-48.000000, -1463.000000)"><g transform="translate(-1001.000000, 1125.000000)"><g transform="translate(0.000000, 16.000000)"><g transform="translate(1090.000000, 350.000000) scale(-1, 1) translate(-1090.000000, -350.000000) translate(1049.000000, 322.000000)"><path d="M31,32.0771645 L33.8284271,32.0771645 L54.0771645,52.3259018 L78.8259018,27.5771645 L54.0771645,2.82842712 L33.8284271,23.0771645 L31,23.0771645 L54.0771645,-1.19015908e-13 L81.6543289,27.5771645 L54.0771645,55.1543289 L31,32.0771645 Z" fill="#C59949" fill-rule="nonzero"></path><polygon fill="#00313F" transform="translate(33.250000, 27.749967) scale(1, -1) translate(-33.250000, -27.749967) " points="53.062532 14 66.5 27.7499672 53.062532 41.4999344 51.5625356 39.9374382 62.7500089 28.8124647 0 28.8124647 0 26.6874698 62.7500089 26.6874698 51.5625356 15.5624963"></polygon></g></g></g></g></g></svg></div>',
          nextArrow: '<div class="slider__arrow slider__arrow--next"><svg width="82px" height="56px" viewBox="0 0 82 56" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs></defs><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g transform="translate(-1309.000000, -1463.000000)"><g transform="translate(-1001.000000, 1125.000000)"><g transform="translate(2294.000000, 16.000000)"><g transform="translate(16.000000, 322.000000)"><path d="M31,32.0771645 L33.8284271,32.0771645 L54.0771645,52.3259018 L78.8259018,27.5771645 L54.0771645,2.82842712 L33.8284271,23.0771645 L31,23.0771645 L54.0771645,-1.19015908e-13 L81.6543289,27.5771645 L54.0771645,55.1543289 L31,32.0771645 Z" fill="#C59949" fill-rule="nonzero"></path><polygon fill="#00313F" transform="translate(33.250000, 27.749967) scale(1, -1) translate(-33.250000, -27.749967) " points="53.062532 14 66.5 27.7499672 53.062532 41.4999344 51.5625356 39.9374382 62.7500089 28.8124647 0 28.8124647 0 26.6874698 62.7500089 26.6874698 51.5625356 15.5624963"></polygon></g></g></g></g></g></svg></div>',
          responsive: [
            {
              breakpoint: 768,
              settings: {
                centerMode: true,
                centerPadding: '8.333%',
                slidesToShow: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                centerMode: true,
                centerPadding: '8.333%',
                slidesToShow: 1
              }
            }
          ]
        });

        var instaAPI = "https://api.instagram.com/v1/users/self/media/recent/?access_token=4214101168.dd3bdf2.62a4c086f0534885943366ceb27d4fdb";
        $.getJSON( instaAPI, function(data){
        }).done(function( data ) {
            $.each( data.data, function( i, item ) {
              $(".instafeed__photo-list").append('<li class="instafeed__photo-item"><a class="instafeed__link" href="'+item.link+'" target="_blank"><div class="instafeed__image" style="background-image:url('+item.images.standard_resolution.url+');"></div><span class="sr-only">View this image on Instagram</span></a></li>');
              if ( i === 3 ) {
                return false;
              }
            });
          });
      }
    },
    // About us page, note the change from about-us to about_us.
    'about': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function() {

        $('.timeline__slide-content')
          // .css('opacity', 0) // immediately hide element
          .addClass('inactive')
          .waypoint(function(direction) {
            if (direction === 'down') {
              // $(this.element).animate({ opacity: 1, duration: 200 })
              $(this.element).addClass('active').removeClass('inactive');
            }
            else {
              // $(this.element).animate({ opacity: 0, duration: 200 })
              $(this.element).addClass('inactive').removeClass('active');
            }
          }, {
            offset: '90%'
          });

        $('.timeline__slide-content')
          // .css('opacity', 0) // immediately hide element
          .addClass('inactive')
          .waypoint(function(direction) {
            if (direction === 'up') {
              // $(this.element).animate({ opacity: 1, duration: 200 })
              $(this.element).addClass('active').removeClass('inactive');
            }
            else {
              // $(this.element).animate({ opacity: 0, duration: 200 })
              $(this.element).addClass('inactive').removeClass('active');
            }
          }, {
            offset: '10%'
          });

        $('.timeline__slide-spacer[data-spacer="1"]')
          .waypoint(function(direction) {
            var currentTimeline = parseInt(this.element.dataset.timeline, 10);
            if (isNaN(currentTimeline)) {
              return;
            }
            var previousTimeline = currentTimeline - 1;
            if (direction === 'up') {
              $('.timeline__slide-background[data-timeline="' + previousTimeline + '"]').animate({ opacity: 1 });
              $('.timeline__slide-background[data-timeline="' + currentTimeline + '"]').animate({ opacity: 0 })
            }
            else {
              $('.timeline__slide-background[data-timeline="' + previousTimeline + '"]').animate({ opacity: 0 });
              $('.timeline__slide-background[data-timeline="' + currentTimeline + '"]').animate({ opacity: 1 })
            }
          }, {
            offset: 'bottom-in-view'
          });

          // Fixes issue in IE11 where nested images in fixed elements jump while scrolling
          if(navigator.userAgent.match(/Trident\/7\./)) {
            document.body.addEventListener("mousewheel", function() {
              event.preventDefault();
              var wd = event.wheelDelta;
              var csp = window.pageYOffset;
              window.scrollTo(0, csp - wd);
            });
          }
      }
    },
    'rooms': {
      init: function() {

      },
      finalize: function() {
        // init rooms slider 
        $('.single-room__slider .slider__items').each(function() {
          $(this).slick({
            centerMode: true,
            centerPadding: '8.333%',
            slidesToShow: 1,
            lazyLoad: 'progressive',
            variableWidth: true,
            prevArrow: '<div class="slider__arrow slider__arrow--prev"><svg width="82px" height="56px" viewBox="0 0 82 56" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs></defs><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g transform="translate(-48.000000, -1463.000000)"><g transform="translate(-1001.000000, 1125.000000)"><g transform="translate(0.000000, 16.000000)"><g transform="translate(1090.000000, 350.000000) scale(-1, 1) translate(-1090.000000, -350.000000) translate(1049.000000, 322.000000)"><path d="M31,32.0771645 L33.8284271,32.0771645 L54.0771645,52.3259018 L78.8259018,27.5771645 L54.0771645,2.82842712 L33.8284271,23.0771645 L31,23.0771645 L54.0771645,-1.19015908e-13 L81.6543289,27.5771645 L54.0771645,55.1543289 L31,32.0771645 Z" fill="#C59949" fill-rule="nonzero"></path><polygon fill="#00313F" transform="translate(33.250000, 27.749967) scale(1, -1) translate(-33.250000, -27.749967) " points="53.062532 14 66.5 27.7499672 53.062532 41.4999344 51.5625356 39.9374382 62.7500089 28.8124647 0 28.8124647 0 26.6874698 62.7500089 26.6874698 51.5625356 15.5624963"></polygon></g></g></g></g></g></svg></div>',
            nextArrow: '<div class="slider__arrow slider__arrow--next"><svg width="82px" height="56px" viewBox="0 0 82 56" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs></defs><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g transform="translate(-1309.000000, -1463.000000)"><g transform="translate(-1001.000000, 1125.000000)"><g transform="translate(2294.000000, 16.000000)"><g transform="translate(16.000000, 322.000000)"><path d="M31,32.0771645 L33.8284271,32.0771645 L54.0771645,52.3259018 L78.8259018,27.5771645 L54.0771645,2.82842712 L33.8284271,23.0771645 L31,23.0771645 L54.0771645,-1.19015908e-13 L81.6543289,27.5771645 L54.0771645,55.1543289 L31,32.0771645 Z" fill="#C59949" fill-rule="nonzero"></path><polygon fill="#00313F" transform="translate(33.250000, 27.749967) scale(1, -1) translate(-33.250000, -27.749967) " points="53.062532 14 66.5 27.7499672 53.062532 41.4999344 51.5625356 39.9374382 62.7500089 28.8124647 0 28.8124647 0 26.6874698 62.7500089 26.6874698 51.5625356 15.5624963"></polygon></g></g></g></g></g></svg></div>',
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  centerMode: true,
                  centerPadding: '8.333%',
                  slidesToShow: 1
                }
              },
              {
                breakpoint: 480,
                settings: {
                  arrows: false,
                  centerMode: true,
                  centerPadding: '8.333%',
                  slidesToShow: 1
                }
              }
            ]
          });
        });
      }
    },
    'weddings': {
      init: function() {

      },
      finalize: function() {
        // init rooms slider 
        $('.weddings-room__slider .slider__items').each(function() {
          $(this).slick({
            centerMode: true,
            centerPadding: '8.333%',
            slidesToShow: 1,
            lazyLoad: 'progressive',
            variableWidth: true,
            prevArrow: '<div class="slider__arrow slider__arrow--prev"><svg width="82px" height="56px" viewBox="0 0 82 56" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs></defs><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g transform="translate(-48.000000, -1463.000000)"><g transform="translate(-1001.000000, 1125.000000)"><g transform="translate(0.000000, 16.000000)"><g transform="translate(1090.000000, 350.000000) scale(-1, 1) translate(-1090.000000, -350.000000) translate(1049.000000, 322.000000)"><path d="M31,32.0771645 L33.8284271,32.0771645 L54.0771645,52.3259018 L78.8259018,27.5771645 L54.0771645,2.82842712 L33.8284271,23.0771645 L31,23.0771645 L54.0771645,-1.19015908e-13 L81.6543289,27.5771645 L54.0771645,55.1543289 L31,32.0771645 Z" fill="#C59949" fill-rule="nonzero"></path><polygon fill="#00313F" transform="translate(33.250000, 27.749967) scale(1, -1) translate(-33.250000, -27.749967) " points="53.062532 14 66.5 27.7499672 53.062532 41.4999344 51.5625356 39.9374382 62.7500089 28.8124647 0 28.8124647 0 26.6874698 62.7500089 26.6874698 51.5625356 15.5624963"></polygon></g></g></g></g></g></svg></div>',
            nextArrow: '<div class="slider__arrow slider__arrow--next"><svg width="82px" height="56px" viewBox="0 0 82 56" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs></defs><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g transform="translate(-1309.000000, -1463.000000)"><g transform="translate(-1001.000000, 1125.000000)"><g transform="translate(2294.000000, 16.000000)"><g transform="translate(16.000000, 322.000000)"><path d="M31,32.0771645 L33.8284271,32.0771645 L54.0771645,52.3259018 L78.8259018,27.5771645 L54.0771645,2.82842712 L33.8284271,23.0771645 L31,23.0771645 L54.0771645,-1.19015908e-13 L81.6543289,27.5771645 L54.0771645,55.1543289 L31,32.0771645 Z" fill="#C59949" fill-rule="nonzero"></path><polygon fill="#00313F" transform="translate(33.250000, 27.749967) scale(1, -1) translate(-33.250000, -27.749967) " points="53.062532 14 66.5 27.7499672 53.062532 41.4999344 51.5625356 39.9374382 62.7500089 28.8124647 0 28.8124647 0 26.6874698 62.7500089 26.6874698 51.5625356 15.5624963"></polygon></g></g></g></g></g></svg></div>',
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  centerMode: true,
                  centerPadding: '8.333%',
                  slidesToShow: 1
                }
              },
              {
                breakpoint: 480,
                settings: {
                  arrows: false,
                  centerMode: true,
                  centerPadding: '8.333%',
                  slidesToShow: 1
                }
              }
            ]
          });
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');

      //init priority nav & waypoints if viewport larger than 960px
      var nav = priorityNav.init({
            mainNavWrapper: ".site-header__primary-menu",
            mainNav: ".site-menu--primary",
            breakPoint: 0,
            turnOffPoint: 960
      });
      if (window.matchMedia("(min-width: 960px)").matches) {

        // Page nav functions
        if( $('.fixed-page-nav:not(.mobile-only)').length ) {
          
          // fixed anchor links
          var sticky = new Waypoint.Sticky({
            element: $('.fixed-page-nav-wrapper:not(.mobile-only)')
          });
          
          // update anchor links as users scroll
          $(window).scroll(function () {
              var y = $(this).scrollTop();
              $('.anchor-link').each(function (event) {
                  if (y >= $($(this).attr('href')).offset().top - 40) {
                      $('.anchor-link').not(this).removeClass('active');
                      $(this).addClass('active');
                  }
              });
          });
        }

        // Hide fixed header unless scrolling up
        var didScroll;
        var lastScrollTop = 0;
        var delta = 25;
        var navbarHeight = $('.site-header').outerHeight();

        $(window).scroll(function(event){
            didScroll = true;
        });

        setInterval(function() {
            if (didScroll) {
                hasScrolled();
                didScroll = false;
            }
        }, 250);

        function hasScrolled() {
            var st = $(this).scrollTop();
            
            // Make sure they scroll more than delta
            if(Math.abs(lastScrollTop - st) <= delta)
                return;
            
            // If they scrolled down and are past the navbar, add class .nav-up.
            // This is necessary so you never see what is "behind" the navbar.
            if (st > lastScrollTop && st > navbarHeight){
                // Scroll Down
                $('body').removeClass('nav-down').addClass('nav-up');
            } else {
                // Scroll Up
                if(st + $(window).height() < $(document).height()) {
                    $('body').removeClass('nav-up').addClass('nav-down');
                }
            }
            
            lastScrollTop = st;
        }

      }

    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  
})(jQuery); // Fully reference jQuery after this point.
